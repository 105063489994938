import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";
import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { deleteStore, getStores } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import useSearchFormParam from "components/shared/SearchHook";
import BootyPagination from "components/shared/Pagination";
const Table = (props) => {
    const [registerSearch, handleSubmitSearch, params, EmptyButton] =
        useSearchFormParam();
    const dispatch = useDispatch();
    const { locale } = useIntl();
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const paginationQuery = `?page=${params.get("page") ?? "1"}&limit=${
            params.get("limit") ?? "20"
        }`;
        dispatch(getStores(paginationQuery));
        const timeout = setTimeout(() => {
            setRows(rows);
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [dispatch, rows, params]);

    const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
        <div className="form-check">
            <input
                htmlFor="booty-check"
                type="checkbox"
                className="form-check-input"
                ref={ref}
                onClick={onClick}
                {...rest}
            />
            <label className="form-check-label" id="booty-check" />
        </div>
    ));

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = props?.data?.filter(
        (item) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <Filter
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const handleDeleteStore = (id) => {
        dispatch(deleteStore(id));
    };

    const handleShowStore = (id) => {
        navigate(`/stores/show/${id}`);
    };

    const columns = [
        {
            name: "",
            selector: (row) => (
                <img alt="" src={row?.logo} className="store-logo" />
            ),
            sortable: true,
        },

        {
            name: "اسم المتجر",
            selector: (row) => row?.name?.[locale],
            sortable: true,
        },

        {
            name: "الباقة",
            selector: (row) => row?.package?.name?.[locale],
            sortable: true,
        },

        {
            name: "الجوال",
            selector: (row) => row?.phone,
            sortable: true,
        },

        {
            cell: (row) => (
                <>
                    <div className="global-dropdown">
                        <Dropdown>
                            <Dropdown.Toggle>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={(e) => handleShowStore(row.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#fff"
                                        className="bi bi-eye show"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                    عرض
                                </Dropdown.Item>
                                {/* <Dropdown.Item onClick={(e) => handleEditStore(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#fff"
                    className="bi bi-pencil show"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                  تعديل
                </Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={(e) => handleDeleteStore(row.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#fff"
                                        className="bi bi-trash show"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                    </svg>
                                    حذف
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            ),
            allowOverflow: true,
            width: "56px",
        },
    ];

    return (
        <>
            <div className="table-wrap">
                <div className="card">
                    <Col xl={6} md={12} xs={12}>
                        <div className="card-head">
                            <div>
                                <h4>جدول البيانات الافتراضي </h4>
                                <p>
                                    القيام به لاستخدامه مع الجداول الخاصة بك هو
                                    لاستدعاء الدالة البناء:
                                </p>
                            </div>
                        </div>
                    </Col>
                    <div className="card-body">
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            progressPending={pending}
                            progressComponent={<Loader />}
                            defaultSortField="name"
                            selectableRows
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                            selectableRowsComponent={BootyCheckbox}
                        />
                    </div>
                    <BootyPagination metaData={props?.metaData} />
                </div>
            </div>
        </>
    );
};

export default Table;
