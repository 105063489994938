import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import settingsIcon from "assets/svgs/Setting.svg";
import basicIcon from "assets/svgs/settings/1.svg";
import shipIcon from "assets/svgs/settings/2.svg";
import payIcon from "assets/svgs/settings/3.svg";
import walletIcon from "assets/svgs/settings/4.svg";
import currencyIcon from "assets/svgs/settings/5.svg";
import optionsIcon from "assets/svgs/settings/6.svg";
import languageIcon from "assets/svgs/settings/7.svg";
import maintenanceIcon from "assets/svgs/settings/9.svg";
import reviewIcon from "assets/svgs/settings/10.svg";
import restrictsIcon from "assets/svgs/settings/11.svg";
import securityIcon from "assets/svgs/settings/12.svg";
import alarmIcon from "assets/svgs/settings/13.svg";
import billsIcon from "assets/svgs/settings/14.svg";
import stockIcon from "assets/svgs/settings/16.svg";

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const href = window?.location?.pathname;

  const updateParams = (name, value) => {
    searchParams.set(name, value);
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`${href}${paramsStr}`, { replace: true });
  };

  return (
    <div className="settings">
      <Tab.Container
        defaultActiveKey={searchParams.get("tab") || "0"}
        transition={true}
        timeout={1000}
      >
        <div className="settingsHead">
          <div className="card">
            <div>
              <span>
                <img src={settingsIcon} alt="" />
              </span>
              الإعدادات
            </div>
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey={0} onClick={() => updateParams("tab", 0)}>
                  إعدادات المتجر
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={1} onClick={() => updateParams("tab", 1)}>
                  إعدادات المنتجات
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey={2} onClick={() => updateParams("tab", 1)}>
                  إعدادات المحتوى
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey={2} onClick={() => updateParams("tab", 2)}>
                  إعدادات متقدمة
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={4} onClick={() => updateParams("tab", 3)}>
                  الأرشيف
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="0">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={basicIcon} alt="" className="overlay" />
                    <Link to="/settings/basicSettings"></Link>
                    <div className="c-img">
                      <img src={basicIcon} alt="" />
                    </div>
                    <h4>الإعدادات الأساسية</h4>
                    <span>الرابط، الشعار، الاسم، المقر</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={shipIcon} alt="" className="overlay" />
                    <Link to="/settings/store-shipping"></Link>
                    <div className="c-img">
                      <img src={shipIcon} alt="" />
                    </div>
                    <h4>خيارات الشحن والتوصيل</h4>
                    <span>الربط مع شركات الشحن</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={payIcon} alt="" className="overlay" />
                    <Link to="/settings/storePaymentMethods"></Link>
                    <div className="c-img">
                      <img src={payIcon} alt="" />
                    </div>
                    <h4>طرق الدفع</h4>
                    <span>تفعيل المدفوعات الإلكترونية</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={walletIcon} alt="" className="overlay" />
                    <Link to="/settings/store-wallet"></Link>
                    <div className="c-img">
                      <img src={walletIcon} alt="" />
                    </div>
                    <h4>المحفظة والفواتير</h4>
                    <span>الرابط، الشعار، الاسم، المقر</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={currencyIcon} alt="" className="overlay" />
                    <Link to="/settings/currencies"></Link>
                    <div className="c-img">
                      <img src={currencyIcon} alt="" />
                    </div>
                    <h4>الــعملات</h4>
                    <span>العملات المتاحة في المتجر</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={optionsIcon} alt="" className="overlay" />
                    <Link to="/settings/store-options"></Link>
                    <div className="c-img">
                      <img src={optionsIcon} alt="" />
                    </div>
                    <h4>خـيارات المتجر</h4>
                    <span>خيارات التحكم بالمتجر</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={languageIcon} alt="" className="overlay" />
                    <Link to="/settings/languages"></Link>
                    <div className="c-img">
                      <img src={languageIcon} alt="" />
                    </div>
                    <h4>الـلغات</h4>
                    <span>تفعيل تعدد اللغات في المتجر</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={maintenanceIcon} alt="" className="overlay" />
                    <Link to="/settings/domain"></Link>
                    <div className="c-img">
                      <img src={maintenanceIcon} alt="" />
                    </div>
                    <h4>اعدادات الدومين</h4>
                    <span>التحكم في خصائص الرابط</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={maintenanceIcon} alt="" className="overlay" />
                    <Link to="/settings/maintenance"></Link>
                    <div className="c-img">
                      <img src={maintenanceIcon} alt="" />
                    </div>
                    <h4>وضع الصيانة</h4>
                    <span>إغلاق المتجر بشكل مؤقتاً</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={reviewIcon} alt="" className="overlay" />
                    <Link to="/settings/store"></Link>
                    <div className="c-img">
                      <img src={reviewIcon} alt="" />
                    </div>
                    <h4>إعدادات التقييم</h4>
                    <span>تحديد إعدادات التقييم الخاصة بمتجرك</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={restrictsIcon} alt="" className="overlay" />
                    <Link to="/settings/store"></Link>
                    <div className="c-img">
                      <img src={restrictsIcon} alt="" />
                    </div>
                    <h4>قيود شركات الشحن</h4>
                    <span>إضافة قيود محددة لشركات الشحن</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={securityIcon} alt="" className="overlay" />
                    <Link to="/settings/storePaymentRules"></Link>
                    <div className="c-img">
                      <img src={securityIcon} alt="" />
                    </div>
                    <h4>قيود الدفع</h4>
                    <span>إضافة قيود محددة</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={alarmIcon} alt="" className="overlay" />
                    <Link to="/settings/storeNotifications"></Link>
                    <div className="c-img">
                      <img src={alarmIcon} alt="" />
                    </div>
                    <h4>الإشعارات</h4>
                    <span>إعداد الإشعارات الخاصة بك</span>
                  </div>
                </div>
              </Col>

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={billsIcon} alt="" className="overlay" />
                    <Link to="/settings/storeReceipts"></Link>
                    <div className="c-img">
                      <img src={billsIcon} alt="" />
                    </div>
                    <h4>اعدادت فواتير المتجر</h4>
                    <span>التحكم بالفواتير الصادرة مع الطلبات</span>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={stockIcon} alt="" className="overlay" />
                    <Link to="/settings/seo"></Link>
                    <div className="c-img">
                      <img src={stockIcon} alt="" />
                    </div>
                    <h4>SEO</h4>
                    <span>تحسين الظهور على محركات البحث </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="1">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={alarmIcon} alt="" className="overlay" />
                    <Link to="/settings/productsSettings"></Link>
                    <div className="c-img">
                      <img src={alarmIcon} alt="" />
                    </div>
                    <h4>اعلمني عند التوفر</h4>
                    <span>إرسال تنبيه إلى العميل في حال توفر المنتج</span>
                  </div>
                </div>
              </Col>

              {/* <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={brandsIcon} alt="" className="overlay" />
                    <Link to="/settings/store"></Link>
                    <div className="c-img">
                      <img src={brandsIcon} alt="" />
                    </div>
                    <h4>الماركات التجارية</h4>
                    <span>عرض الماركات التجارية والتحكم بها</span>
                  </div>
                </div>
              </Col> */}

              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={stockIcon} alt="" className="overlay" />
                    <Link to="/settings/stockSettings"></Link>
                    <div className="c-img">
                      <img src={stockIcon} alt="" />
                    </div>
                    <h4>إعدادات المخزون</h4>
                    <span>
                      تحديد الإعدادات الخاصة بالمنتجات وكمياتها وطريقة عرضها
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab.Pane>
          {/* <Tab.Pane eventKey="2">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={walletIcon} alt="" className="overlay" />
                    <Link to="/settings/store-faqs"></Link>
                    <div className="c-img">
                      <img src={walletIcon} alt="" />
                    </div>
                    <h4>
                      <FormattedMessage id="storeFaqs" />
                    </h4>
                    <span>
                      <FormattedMessage id="storeFaqsDesc" />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab.Pane> */}
          <Tab.Pane eventKey="2">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                <div className="settingItem">
                  <div className="card">
                    <img src={optionsIcon} alt="" className="overlay" />
                    <Link to="/settings/storeApps"></Link>
                    <div className="c-img">
                      <img src={optionsIcon} alt="" />
                    </div>
                    <h4>التطبيقات</h4>
                    <span>تطبيقات متجر تجارة</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Settings;
