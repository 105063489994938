import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Row } from "react-bootstrap";
import update from "immutability-helper";
import ProductCard from "./ProductCard";

// import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
// import { ReactComponent as RemoveIcon } from "assets/svgs/close-icon.svg";
import {
  deleteProduct,
  getSingleProductCategory,
  hideProduct,
  orderProducts,
} from "store/actions";
import { useSearchParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import { Card } from "./card";
// import { useIntl } from "react-intl";
import { ReactComponent as EyeSlashIcon } from "assets/svgs/product/eye-slash.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import Swal from "sweetalert2";
import BootyPagination from "components/shared/Pagination";

// let dropDownTimeout = null;
const ProductList = ({
  listView,
  // metadata,
  isCheck,
  handleClick,
  duplicateProduct,
}) => {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const paramsArray = Object.entries(queryParams);

  const { products, metadata } = useSelector((state) => state.products);
  const { singleCategory } = useSelector((state) => state.productCategories);

  const getDecodedParam = (param) => {
    try {
      return JSON.parse(decodeURI(searchParams?.get(param)));
    } catch (error) {
      return decodeURI(searchParams?.get(param));
    }
  };

  const [cards, setCards] = useState();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState([]);

  const arrangeProductsFlag = !!searchParams.get("arrange");

  const productsAsStr = JSON.stringify(
    arrangeProductsFlag ? singleCategory?.products || [] : products
  );

  useEffect(() => {
    if (arrangeProductsFlag) setCards(singleCategory?.products || []);
    else setCards(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsStr, singleCategory]);

  // const handleDeleteProduct = () => {
  //   Swal.fire({
  //     title: `هل تريد حذف ${isCheck?.length} منتجات!`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#2a89f3",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "نعم",
  //     cancelButtonText: "لا",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       if (isCheck?.length > 0)
  //         dispatch(
  //           // passing the selected products + the search params for pagination info
  //           deleteProducts({
  //             products: isCheck,
  //             searchParams: Object.fromEntries([...searchParams]),
  //           })
  //         );
  //       setIsCheck([]);
  //     }
  //   });
  // };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) =>
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      ),
    []
  );
  // const handleHighOrderDrop = (shownFlag, e) => {
  //   if (dropDownTimeout !== null) clearTimeout(dropDownTimeout);

  //   const mainParent = e?.originalEvent?.target?.closest(".col-12"),
  //     dropDown = e?.originalEvent?.target?.closest(".dropdown");
  //   console.log(
  //     "dropDown?.classList?.contains(show)",
  //     dropDown?.classList?.contains("show")
  //   );
  //   console.log("dropDown", dropDown);
  //   dropDownTimeout = setTimeout(() => {
  //     if (mainParent)
  //       if (dropDown?.classList?.contains("show")) {
  //         mainParent.style.position = "relative";
  //         mainParent.style.zIndex = "1";
  //       } else {
  //         mainParent.style.position = "";
  //         mainParent.style.zIndex = "0";
  //       }
  //   }, 50);

  //   console.log("high oreder e");
  // };

  const handleHideProduct = (product) => {
    Swal.fire({
      title: formatMessage({
        id: product?.isShown ? "hideProduct" : "showProduct",
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(hideProduct(getId(product)));
      }
    });
  };

  const handleDeleteProduct = (id) => {
    Swal.fire({
      title: "هل تريد حذف المنتج!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProduct(id));
      }
    });
  };

  const renderProducts = useMemo(
    () =>
      cards?.map((product, index) => {
        return (
          <Dropdown
            className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3"
            // onToggle={handleHighOrderDrop}
            key={getId(product)}
          >
            <Card
              index={index}
              id={getId(product)}
              text={product.name?.[locale]}
              moveCard={moveCard}
            >
              {(drag, preview) => (
                <ProductCard
                  preview={preview}
                  drag={drag}
                  product={product}
                  isCheck={isCheck}
                  handleClick={handleClick}
                  duplicateProduct={duplicateProduct}
                  // handleHighOrderDrop={handleHighOrderDrop}
                />
              )}
            </Card>
            {/* </Col> */}
            <Dropdown.Menu>
              <Dropdown.Item
                className="delete"
                onClick={() => {
                  duplicateProduct(product);
                }}
              >
                <CopyIcon fill="#FC6B14" />
                <FormattedMessage id="duplicate" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleHideProduct(product)}>
                <EyeSlashIcon fill="#FC6B14" />
                <FormattedMessage
                  id={product?.isShown ? "hideProduct" : "showProduct"}
                />
              </Dropdown.Item>
              <Dropdown.Item
                className="delete"
                onClick={() => handleDeleteProduct(getId(product))}
              >
                <DeleteIcon fill="#f00" />
                <FormattedMessage id="delete" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards, handleClick, isCheck, locale, moveCard]
  );

  useEffect(() => {
    setPage(metadata?.pagination?.page || 1);
  }, [metadata]);

  const totalPages = Math.ceil(
    metadata?.totalResults / metadata?.pagination?.limit
  );

  useEffect(() => {
    setParams(paramsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleSaveOrder = () => {
    dispatch(
      orderProducts({
        category: getDecodedParam("categories")?.[0],
        products: cards?.map((card, index) => ({
          id: getId(card),
          orderInCategory: index,
        })),
        callBack: () =>
          dispatch(
            getSingleProductCategory(getDecodedParam("categories")?.[0])
          ),
      })
    );
  };

  const saveBtn = useMemo(
    () =>
      productsAsStr !== JSON?.stringify(cards) && (
        <div className="d-flex justify-content-between align-items-center my-2 border border-secondary p-2 save-arrangement">
          <span className="dark-white mx-2">
            <FormattedMessage id="saveChanges" />
          </span>
          <button className="btn btn-blue" onClick={handleSaveOrder}>
            <FormattedMessage id="save" />
          </button>
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [products, cards]
  );

  return (
    <div className={listView ? "productsList listView" : "productsList"}>
      {saveBtn}

      <Row>
        <DndProvider backend={HTML5Backend}>
          {cards?.length > 0 ? (
            renderProducts
          ) : (
            <div className="no-data alert alert-warning text-center m-0 mt-4">
              <p>لا توجد منتجات</p>
            </div>
          )}
        </DndProvider>
      </Row>

      {/* {page < totalPages && products?.length > metadata?.pagination?.limit && (
        <PaginationControl
          page={page}
          between={3}
          total={metadata?.totalResults}
          limit={metadata?.pagination?.limit}
          changePage={(page) => {
            setPage(page);
            dispatch(
              loadMoreProducts({
                currentPage: page,
              })
            );
          }}
          ellipsis={1}
        />
      )} */}
      <BootyPagination metaData={metadata} />
      {/* {loading && <StickyLoader fill="#FC6B14" />} */}
    </div>
  );
};

export default ProductList;
