import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";

import Loader from "components/shared/Loader";
import StickyLoader from "components/shared/StickyLoader";
import BreadCrumb from "components/shared/BreadCrumb";
import { getBankAccounts, getBanks, getSettings } from "store/actions";

import { ReactComponent as NotifIcon } from "assets/svgs/settings/13.svg";

const StoreNotifications = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { register, handleSubmit } = useForm();

  const { dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("paymentMethodsSettings"));
    dispatch(getBanks());
    dispatch(getBankAccounts());
  }, [dispatch]);

  const CheckLabel = ({ name, label = name }) => (
    <tr className="shipping-card">
      <td className="info">
        <p>
          <FormattedMessage id={label} />
        </p>
      </td>

      <td className="switcher-wrapper">
        <label className="switcher">
          <input
            type="checkbox"
            className="switcher-input"
            {...register(name)}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </td>
      <td className="switcher-wrapper">
        <label className="switcher">
          <input
            type="checkbox"
            className="switcher-input"
            {...register(`${name}_emailNotify`)}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </td>
    </tr>
  );

  const onSubmit = (data) => {
    console.log(data);
  };

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page store-notifications">
      <BreadCrumb
        pageName={formatMessage({
          id: "notifications",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="acc-form card shipping-methods">
          {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
          <div className="card-head productDataModal">
            <div className="form-group mb-0">
              <div className="form-head mb-0">
                <i>
                  <NotifIcon />
                </i>
                <div>
                  <h4>
                    <FormattedMessage id="notifications" />
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <table className="">
            <thead>
              <th>
                <FormattedMessage id="notifyType" />
              </th>
              <th>
                <FormattedMessage id="websiteNotify" />
              </th>
              <th>
                <FormattedMessage id="emailNotify" />
              </th>
            </thead>
            <CheckLabel name="newOrders" />
            <CheckLabel name="addProductToCard" />
            <CheckLabel name="storeRate" />
            <CheckLabel name="productsRate" />
            <CheckLabel name="deliveryRate" />
            <CheckLabel name="customerQuestion" />
            <CheckLabel name="activateStoreEmployeesAccounts" />
          </table>
        </div>
      </form>
    </div>
  );
};

export default StoreNotifications;
