import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Editor } from "components/shared/Editor";
import {
  addBlog,
  getBlogCategories,
  getSettings,
  getTags,
} from "store/actions";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import ImageUpload from "components/shared/ImageUpload";

const AddBlog = () => {
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale } = useIntl();
  const { blogCategories } = useSelector((state) => state.blogCategories);
  const { settings } = useSelector((state) => state.settings || {});
  const { languages } = settings;
  const { blogTags } = useSelector((state) => state.blogs) || {};
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const renderCategoriesSelectOptions = () => {
    return blogCategories.map((category, index) => {
      return (
        <option value={category.id} key={index}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages?.map((language, index) => {
      return (
        <option value={language?._id} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getSettings("languageSettings"));
    dispatch(getTags());
  }, [dispatch]);

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data?.image?.split("/").pop();
    data.description = description;
    data.tags = tags;
    dispatch(addBlog({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف مقال جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة المقال</h5>
                      <Controller
                        control={control}
                        name="image"
                        render={({ field: { value, onChange } }) => (
                          <ImageUpload
                            selectedImage={value}
                            setSelectedImage={({ path }) => onChange(path)}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>التصنيف</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("category", { required: true })}
                        >
                          <option value="">اختر التصنيف</option>
                          {renderCategoriesSelectOptions()}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors.category?.type === "required" &&
                          "يرجي اختيار التصنيف"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>لغة المقال</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("language", { required: true })}
                        >
                          <option value="">اختر لغة المقال</option>
                          {renderLanguagesSelectOptions()}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors.language?.type === "required" &&
                          "يرجي اختيار لغة المقال"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان المقال</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان المقال"
                          {...register("title", {
                            required: true,
                          })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.title?.type === "required" &&
                          "يرجي ادخال عنوان المقال"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>وصف المقال</h5>
                      <div>
                        <Controller
                          control={control}
                          name="description"
                          render={({ field: { value } }) => (
                            <Editor
                              initialValue=""
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                setDescription(data);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group">
                      <h5>اضافة كلمات دلالية</h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <CreatableSelect
                              isMulti
                              isRtl={true}
                              className="basic-single creatable-select"
                              classNamePrefix="select"
                              placeholder="اضف كلمات دلالية"
                              onChange={(selected) => {
                                const newTags = selected.map(
                                  (tag) => tag.label
                                );

                                setTags(newTags);
                              }}
                              options={blogTags.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.tags?.type === "required" &&
                          "يرجي ادخال الكلمات الدلالية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة المقال
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
