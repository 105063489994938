import server from "./server";

export const getCouponsApi = async (payload) => {
  const response = await server().get(`/stores/coupons${payload || ""}`);
  return response.data;
};

export const getSingleCouponApi = async (id) => {
  const response = await server().get(`/stores/coupons/${id}`);
  return response.data;
};

export const addCouponApi = async (payload) => {
  const response = await server().post("/stores/coupons", payload);
  return response.data;
};

export const editCouponApi = async ({ id, data }) => {
  const response = await server().put(`/stores/coupons/${id}`, data);
  return response.data;
};

export const deleteCouponApi = async (id) => {
  const response = await server().delete(`/stores/coupons/${id}`);
  return response.data;
};

export const activateCouponApi = async (id) => {
  const response = await server().post(
    `/stores/coupons/activationCoupon/${id}`
  );
  return response.data;
};
