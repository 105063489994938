import server from "./server";

export const getProductsReviewsApi = async (payload) => {
  const response = await server().get(
    `/stores/products/reviews${payload || ""}`
  );
  return response.data;
};

export const getSingleProductsReviewApi = async (id) => {
  const response = await server().get(`/stores/products/reviews/${id}`);
  return response.data;
};

export const addProductsReviewApi = async (payload) => {
  const response = await server().post("/stores/products/reviews", payload);
  return response.data;
};

export const editProductsReviewApi = async ({ id, data }) => {
  const response = await server().put(`/stores/products/reviews/${id}`, data);
  return response.data;
};

export const replyToProductsReviewApi = async ({ id, data }) => {
  const response = await server().post(
    `/stores/products/reviews/addAnswer/${id}`,
    data
  );
  return response.data;
};

export const changeProductsReviewStatusApi = async ({ id, data }) => {
  const response = await server().post(
    `/stores/products/reviews/changeStatus/${id}`,
    data
  );
  return response.data;
};

export const deleteProductsReviewApi = async (id) => {
  const response = await server().delete(`/stores/products/reviews/${id}`);
  return response.data;
};
