import server from "./server";

export const getBrandsApi = async (payload) => {
  const response = await server().get(
    `/stores/products/brands${payload || ""}`
  );
  return response.data;
};

export const getSingleBrandApi = async (id) => {
  const response = await server().get(`/stores/products/brands/${id}`);
  return response.data;
};

export const addBrandApi = async (payload) => {
  const response = await server().post("/stores/products/brands", payload);
  return response.data;
};

export const editBrandApi = async ({ id, data }) => {
  const response = await server().put(`/stores/products/brands/${id}`, data);
  return response.data;
};

export const deleteBrandApi = async (id) => {
  const response = await server().delete(`/stores/products/brands/${id}`);
  return response.data;
};

export const getBrandsSettingApi = async () => {
  const response = await server().get("/settings/brandSettings");
  return response.data;
};

export const editBrandsSettingApi = async (data) => {
  const response = await server().put("/settings/brandSettings", data);
  return response.data;
};

export const orderBrandsSettingApi = async (data) => {
  const response = await server().put(
    "/stores/products/brands/reorderBrands",
    data
  );
  return response.data;
};

export const deleteMultipleBrandsApi = async (data) => {
  const response = await server().delete(
    "/stores/products/brands/deleteMultiBrands",
    {
      data,
    }
  );
  return response.data;
};
