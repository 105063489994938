import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getUnitsApi } from "api/measuringUnits";

// Login Redux States
import { GET_UNITS } from "./actionTypes";

import { getUnitsFailure, getUnitsSuccess } from "./actions";

function* getUnits() {
    try {
        const { data } = yield call(getUnitsApi);
        console.log("Measurment Units >>>", data);
        yield put(getUnitsSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getUnitsFailure(error?.response?.data?.errors?.[0]?.msg));
    }
}

export function* watchGetUnits() {
    yield takeEvery(GET_UNITS, getUnits);
}

function* unitsSaga() {
    yield all([fork(watchGetUnits)]);
}

export default unitsSaga;
