import server from "./server";

export const getBlogCategoriesApi = async () => {
  const response = await server().get("/stores/blogCategories");
  return response.data;
};

export const getSingleBlogCategoryApi = async (id) => {
  const response = await server().get(`/stores/blogCategories/${id}`);
  return response.data;
};

export const addBlogCategoryApi = async (payload) => {
  const response = await server().post("/stores/blogCategories", payload);
  return response.data;
};

export const editBlogCategoryApi = async ({ id, data }) => {
  const response = await server().put(`/stores/blogCategories/${id}`, data);
  return response.data;
};

export const deleteBlogCategoryApi = async (id) => {
  const response = await server().delete(`/stores/blogCategories/${id}`);
  return response.data;
};
