/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccounts, getBanks, getSettings } from "store/actions";
import Loader from "components/shared/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as ShipIcon } from "assets/svgs/settings/2.svg";
import { ReactComponent as WalletIcon } from "assets/svgs/settings/3.svg";
import { ReactComponent as EditCardIcon } from "assets/svgs/settings/card-edit.svg";
import { ReactComponent as BankIcon } from "assets/svgs/bank.svg";
import { ReactComponent as EmptyRocketIcon } from "assets/svgs/empty-rocket.svg";
import { ReactComponent as CashIcon } from "assets/svgs/cash.svg";
import { ReactComponent as TruckIcon } from "assets/svgs/product/truck.svg";
import { ReactComponent as DollarIcon } from "assets/svgs/product/dollar.svg";
import { ReactComponent as ContractIcon } from "assets/svgs/contract.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow.svg";

import { handleImage } from "helpers/functions";
import FreeShippingModal from "components/settings/storeShipping/FreeShippingConditions";
import CashOnDeliveryModal from "components/settings/storeShipping/CashOnDeliveryModal";
import DeliveryCompaniesOptions from "components/settings/storeShipping/DeliveryCompaniesOptions";
import CalculateShipingModal from "components/settings/storeShipping/CalculateShipingModal";
import BankModal from "components/settings/storeWallet/BankModal";
import BankAccountsCards from "components/settings/storeWallet/BankAccountsCards";
import BreadCrumb from "components/shared/BreadCrumb";

const StorePaymentMethods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { formatMessage } = useIntl();

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  const [selectedBank, setSelectedBank] = useState();
  const [bankAccountModal, setBankAccountModal] = useState(false);

  useEffect(() => {
    dispatch(getSettings("paymentMethodsSettings"));
    dispatch(getBanks());
    dispatch(getBankAccounts());
  }, [dispatch]);

  const shipping = settings?.shipping;

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page store-paymentmethods">
      <BreadCrumb
        pageName={formatMessage({
          id: "paymentMethods",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0" className="delivery-companies">
          <div className="acc-form card shipping-methods">
            {/* -------------------------------------- */}
            <Accordion.Header>
              <div className="card-head m-0 productDataModal">
                <div className="form-group mb-0">
                  <div className="form-head mb-0">
                    <i>
                      <WalletIcon />
                    </i>
                    <div>
                      <h4>
                        <FormattedMessage id="paymentMethods" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="items settings-cards">
                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "free-shipping")}
                >
                  <div className="icon">
                    <EmptyRocketIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="freeShippingSettings" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="freeShippingSettingsDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "cash-delivery")}
                >
                  <div className="icon">
                    <CashIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="cashOnDelivery" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="cashOnDeliveryDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "delivery-settings")}
                >
                  <div className="icon">
                    <TruckIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="deliveryCompaniesSetting" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="deliveryCompaniesSettingDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() =>
                    navigate(
                      `${window?.location?.pathname}/deliveryCompaniesRules`
                    )
                  }
                >
                  <div className="icon">
                    <ContractIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="deliveryCompaniesLimits" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="deliveryCompaniesLimitsDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>

                <a
                  className="card shipping-card"
                  onClick={() => updateParams("modal", "delivery-price")}
                >
                  <div className="icon">
                    <DollarIcon />
                  </div>
                  <div className="desc">
                    <p>
                      <FormattedMessage id="calculateShippingFee" />
                    </p>
                    <p className="small">
                      <FormattedMessage id="calculateShippingFeeDesc" />
                    </p>
                  </div>
                  <ArrowIcon />
                </a>
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="delivery-companies">
          <div className="acc-form card shipping-methods">
            {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
            <Accordion.Header>
              <div className="card-head mb-0 productDataModal">
                <div className="form-group mb-0">
                  <div className="form-head mb-0">
                    <i>
                      <ShipIcon />
                    </i>
                    <div>
                      <h4>
                        <FormattedMessage id="digitalWallets" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="items">
                {(shipping?.methods || [1, 2, 3])?.map((method, index) => (
                  <div className="card shipping-card" key={index}>
                    <div className="info">
                      <img
                        src={handleImage(
                          method?.image ||
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Aramex_logo.svg/2560px-Aramex_logo.svg.png"
                        )}
                        alt=""
                      />
                      <p>{method?.title || "شركة الشحن السريعة"}</p>
                    </div>
                    <Link to={`/settings/store-shipping/${method?.id}`}>
                      <FormattedMessage id="moreSettings" />
                    </Link>
                    <div className="switcher-wrapper">
                      <label className="switcher">
                        <input type="checkbox" className="switcher-input" />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="delivery-companies">
          <div className="acc-form card shipping-methods">
            {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
            <Accordion.Header>
              <div className="card-head mb-0 productDataModal">
                <div className="form-group mb-0">
                  <div className="form-head mb-0">
                    <i>
                      <EditCardIcon />
                    </i>
                    <div>
                      <h4>
                        <FormattedMessage id="buyNowPayLater" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="items">
                {(shipping?.methods || [1, 2, 3])?.map((method, index) => (
                  <div className="card shipping-card" key={index}>
                    <div className="info">
                      <img
                        src={handleImage(
                          method?.image ||
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Aramex_logo.svg/2560px-Aramex_logo.svg.png"
                        )}
                        alt=""
                      />
                      <p>{method?.title || "شركة الشحن السريعة"}</p>
                    </div>
                    <Link to={`/settings/store-shipping/${method?.id}`}>
                      <FormattedMessage id="moreSettings" />
                    </Link>
                    <div className="switcher-wrapper">
                      <label className="switcher">
                        <input type="checkbox" className="switcher-input" />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="delivery-companies">
          <Row>
            <Col xs="9">
              <Accordion.Header className="card">
                <div className="card-head m-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      {/* <i> */}
                      <BankIcon />
                      {/* </i> */}
                      <div>
                        <h4>
                          <FormattedMessage id="bankAccounts" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
            </Col>
            <Col xs="3">
              <button
                className="btn btn-blue btn-sm w-100 add-company"
                onClick={() => setBankAccountModal(true)}
              >
                <FormattedMessage id="addBankAccount" />
              </button>
            </Col>
          </Row>

          <Accordion.Body className="px-0">
            <div className="acc-form card shipping-methods bank-accounts">
              {/* <BankAccounts setSelectedBank={setSelectedBank} /> */}
              <BankAccountsCards
                titleShow={false}
                setSelectedBank={(data) => {
                  setSelectedBank(data);
                  setBankAccountModal(true);
                }}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <BankModal
        selectedBank={selectedBank}
        modalShow={bankAccountModal}
        setModalShow={setBankAccountModal}
        settings={settings}
      />

      <FreeShippingModal show={params?.get("modal") === "free-shipping"} />
      <CashOnDeliveryModal show={params?.get("modal") === "cash-delivery"} />
      <DeliveryCompaniesOptions
        show={params?.get("modal") === "delivery-settings"}
      />
      {/* <DeliveryCompaniesRules
          show={params?.get("modal") === "delivery-rules"}
        /> */}
      <CalculateShipingModal show={params?.get("modal") === "delivery-price"} />
    </div>
  );
};

export default StorePaymentMethods;
