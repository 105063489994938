import React from "react";
import { ReactComponent as BarCodeIcon } from "assets/svgs/settings/barcode.svg";
import { ReactComponent as LogoIcon } from "assets/svgs/settings/logo-placeholder.svg";

export default function ReceiptTemplate({ watch }) {
  return (
    <div className="receipt-template">
      <div className="logos">
        <LogoIcon />
        {watch("showBarCode") && <BarCodeIcon />}
      </div>

      <div className="invoice">
        <div className="invoice-header">
          <p>
            الفاتورة الضريبية : <span>#33</span>
          </p>
          <p>
            رقم الطلب: <span>#33</span>
          </p>

          <p>
            <span>الأربعاء 20 يوليو 2022 | 12:58 م</span>
          </p>
          <p>
            رمز الفاتورة:
            <span>0200000</span>
          </p>
          <p>
            الرقم الضريبي:
            <span>1234567890</span>
          </p>
        </div>
        <div className="sections-wrapper">
          <div className="invoice-section">
            <h2>مصدرة من:</h2>
            <p>المتجر الالكتروني: اسم التاجر الالكتروني</p>
            {watch("showStoreAddress") && <p>السعودية، مكة المكرمة</p>}
            <p>merchant@salla.sa</p>
            <p> 05991198</p>
          </div>
          <div className="invoice-section">
            <h2>مصدرة الى:</h2>
            <p>اسم المشتري الشخصي</p>
            <p>السعودية، مكة المكرمة</p>
            <p>حي شارع خالد بن الوليد، مكة المكرمة</p>
            {watch("showContactInfo") && (
              <>
                <p>05345345</p>
                <p>customer@tjaara.sa</p>
              </>
            )}
          </div>
        </div>
        <div className="invoice-section">
          <h2>تفاصيل المنتجات</h2>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>#</th>
                {watch("showProductImage") && <th>صورة المنتج</th>}
                <th>اسم المنتج/الوصف</th>
                <th>رقم المنتج</th>
                {watch("showProductWeight") && <th> الوزن</th>}
                <th>السعر</th>
                <th>الكمية</th>
                <th>الإجمالي</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                {watch("showProductImage") && (
                  <td className="product-img">
                    <LogoIcon alt="Product Image" width="50" />
                  </td>
                )}
                <td>
                  منتج تجريبي 1
                  {watch("showProductDescription") && (
                    <div className="desc">
                      الوصف التجريبي لاختبار ظهوره في الفاتورة
                    </div>
                  )}
                  {watch("showProductStorageSerial") && (
                    <div className="mt-2 desc">SKU 1826</div>
                  )}
                </td>
                <td>Z.1234.56789</td>
                {watch("showProductWeight") && <td>2 KG</td>}
                <td>500 SAR</td>
                <td>1</td>
                <td>500 SAR</td>
              </tr>
              <tr>
                <td>2</td>
                {watch("showProductImage") && (
                  <td className="product-img">
                    <LogoIcon alt="Product Image" width="50" />
                  </td>
                )}
                <td>
                  منتج تجريبي 2
                  {watch("showProductDescription") && (
                    <div className="desc">
                      الوصف التجريبي لاختبار ظهوره في الفاتورة
                    </div>
                  )}
                  {watch("showProductStorageSerial") && (
                    <div className="mt-2 desc">SKU 1826</div>
                  )}
                </td>
                <td>Z.1234.56789</td>
                {watch("showProductWeight") && <td>3 KG</td>}
                <td>500 SAR</td>
                <td>1</td>
                <td>500 SAR</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="invoice-section">
          <table className="invoice-table total">
            <tbody>
              <tr>
                <td>اجمالي المنتجات</td>
                <td className="total">1000 SAR</td>
              </tr>
              <tr>
                <td>الخصومات (ZID10)</td>
                <td className="total">-50 SAR</td>
              </tr>
              <tr>
                <td>التوصيل</td>
                <td className="total">0 SAR</td>
              </tr>
              <tr>
                <td>دفع عند الاستلام</td>
                <td className="total">45 SAR</td>
              </tr>
              <tr>
                <td className="orange">المجموع الكلي</td>
                <td className="total orange">995 SAR</td>
              </tr>
            </tbody>
          </table>
        </div>
        {watch("showOrderNote") && (
          <div className="invoice-section notes">
            <h2>ملاحظات الطلبات</h2>
            <ul>
              <li>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة.</li>
              <li>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة.</li>
            </ul>
          </div>
        )}
        {watch("showReceiptSummeryAcknowledgment") && (
          <div className="invoice-footer invoice-section">
            <h2>الإقرار</h2>
            <ul>
              <li>
                يقر العميل بأنه قد استلم المنتجات ويوافق على الشروط والأحكام.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
