import server from "./server";

export const getFaqsApi = async (payload) => {
  const response = await server().get(`/stores/faqs${payload || ""}`);
  return response.data;
};

export const getSingleFaqApi = async (id) => {
  const response = await server().get(`/stores/faqs/${id}`);
  return response.data;
};

export const addFaqApi = async (payload) => {
  const response = await server().post("/stores/faqs", payload);
  return response.data;
};

export const editFaqApi = async ({ id, data }) => {
  const response = await server().put(`/stores/faqs/${id}`, data);
  return response.data;
};

export const changeFaqApi = async ({ id, data }) => {
  const response = await server().put(`/stores/faqs/changeStatus/${id}`, data);
  return response.data;
};

export const deleteFaqApi = async (id) => {
  const response = await server().delete(`/stores/faqs/${id}`);
  return response.data;
};
