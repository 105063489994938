import { Modal, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import { editStoreSlugSetting, getSettings } from "store/actions";

import { ReactComponent as PacakgeIcon } from "assets/svgs/product/quantity.svg";

export default function StockSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { register, handleSubmit, control, reset } = useForm();

  const { settings } = useSelector((state) => state?.settings) || {};

  useEffect(() => {
    dispatch(getSettings("stockAvailable"));
  }, [dispatch]);

  useEffect(() => {
    reset({
      NotifyAvailable: settings?.NotifyAvailable,
      NotifyAvailableVariations: settings?.NotifyAvailableVariations,
      transmitterChannel: settings?.transmitterChannel,
      messageText: settings?.messageText,
      messageTitle: settings?.messageTitle,
    });
  }, [reset, settings]);

  const quantityOptions = [
    "hideQuantity",
    "showQuantityLessThanFive",
    "showQuantity",
  ].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));

  const CheckLabel = ({ name, label = name, subTitle = " ", children }) => (
    <Col md="6" xs="12" className="card shipping-card">
      <div className="info w-100 flex-column align-items-start">
        <p>
          <FormattedMessage id={label} />
        </p>
        <p className="small">
          <FormattedMessage id={subTitle} />
        </p>
        {children}
      </div>

      {!children && (
        <div className="switcher-wrapper p-0 m-0">
          <label
            className="switcher position-relative mb-0"
            style={{ translate: "none", inset: "auto" }}
          >
            <input
              type="checkbox"
              className="switcher-input"
              {...register(`${name}`)}
            />
            <div className="knobs"></div>
            <div className="layer"></div>
          </label>
        </div>
      )}
    </Col>
  );

  const onSubmit = (data) => {
    dispatch(editStoreSlugSetting({ data, slug: "stockSettings", navigate }));
  };

  return (
    <Modal
      show={true}
      size="lg"
      className="productDataModal"
      onHide={() => navigate(-1)}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="products-stock-settings shipping-methods "
      >
        <Modal.Header>
          <Modal.Title>
            <PacakgeIcon />
            <FormattedMessage id="stockSettings" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <table className="w-100"> */}
          <div className="items settings-cards">
            <CheckLabel name="showOutOfStock" subTitle="showOutOfStockDesc" />
            <CheckLabel
              name="allowManualQuantity"
              subTitle="allowManualQuantityDesc"
            />
            <CheckLabel
              name="showOutOfStockAtEnd"
              subTitle="showOutOfStockAtEndDesc"
            />
            <CheckLabel
              name="showRemainingQuantityInProductPage"
              children={
                <Controller
                  control={control}
                  name={`showRemainingQuantityInProductPage`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, selected } }) => (
                    <Select
                      isRtl={true}
                      className="basic-single mt-2 w-100"
                      classNamePrefix="select"
                      options={quantityOptions}
                      onChange={(selected) => {
                        onChange(selected?.value);
                      }}
                      value={quantityOptions?.find(
                        (item) => item?.value === value
                      )}
                    />
                  )}
                />
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-blue">
            <FormattedMessage id="save" />
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="close" />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
