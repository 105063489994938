import { getId } from "helpers/functions";
import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
} from "./actionTypes";

const initialState = {
  orders: [],
  loading: false,
  error: "",
  singleOrder: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: action.payload.orders,
        metadata: {
          ...(action.payload.metadata || {}),
          results: action.payload?.orders?.length,
        },
      };
      break;
    case GET_ORDERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleOrder: action.payload.order,
      };
      break;
    case GET_SINGLE_ORDER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleOrder: {},
      };
      break;

    case ADD_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: action.payload.orders,
      };
      break;
    case ADD_ORDER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // ---------------------------
    // case EDIT_ORDER:
    //   state = {
    //     ...state,
    //     loading: true,
    //   };
    //   break;
    case EDIT_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleOrder: {
          ...(state?.singleOrder || {}),
          status: action.payload?.order?.status,
        },
      };
      break;
    case EDIT_ORDER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ORDER_SUCCESS:
      const ordersAfterDeleteing = [
        ...state?.orders?.filter((group) => getId(group) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        orders: ordersAfterDeleteing,
      };
      break;
    case DELETE_ORDER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
