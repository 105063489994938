import React, { useEffect } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SecIcon } from "assets/svgs/settings/security-card.svg";
import Select from "react-select";
import RuleTypeInputs from "./RuleTypeInputs";

const RestrictionModal = ({ show, handleClose, update, selectedItem }) => {
  const { locale, formatMessage } = useIntl();
  const { paymentMethods } =
    useSelector((state) => state?.paymentMethods) || {};

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: "rules",
  });

  useEffect(() => {
    if (selectedItem?.name) reset(selectedItem);
  }, [reset, selectedItem]);

  const rulesGenres = [
    "cartTotal",
    "productsQuantity",
    "countryCode",
    "countryIp",
    "cardCountry",
    "customerGroup",
    "categoryProducts",
  ].map((e) => ({
    label: formatMessage({ id: e }),
    value: e,
  }));
  const renderMethodsOptions = paymentMethods?.map((c) => ({
    label: c?.name?.[locale] || c?.name,
    value: c?.id,
  }));
  const onSubmit = (data) => {
    data.bank = data?.bank?.value || data?.bank;

    // dispatch(
    //   editBankAccount({
    //     data,
    //     id: getId(selectedBank),
    //     callBack: handleClose,
    //     // navigate,
    //   })
    // );
  };
  return (
    <Modal
      dialogClassName="productDataModal storePayment"
      backdrop="static"
      show={!!show}
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <SecIcon fill="#E4E4E4" />
          <FormattedMessage id="addNewRestriction" />
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Form.Group className={`mb-3 col-12`}>
              <Form.Label>
                <FormattedMessage id="restrictionTitle" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={formatMessage({ id: "title" })}
                {...register(`title`, {
                  required: true,
                })}
              />
              {errors?.title?.type === "required" && (
                <span className="error-hint">
                  <FormattedMessage id="required" />
                </span>
              )}
            </Form.Group>
            <h5 className="fw-bold form-label">
              1- <FormattedMessage id="selectPaymentMethod" />
            </h5>
            <Form.Group className={`mb-3 col-12`}>
              <Form.Label>
                <FormattedMessage id="paymentMethods" />
              </Form.Label>
              <Controller
                control={control}
                name={`paymentMethods`}
                rules={{ required: true }}
                render={({ field: { onChange, value, selected } }) => (
                  <Select
                    isRtl={true}
                    isSearchable={true}
                    className="basic-single form-control"
                    classNamePrefix="select"
                    options={renderMethodsOptions}
                    placeholder="اختار طرق الدفع"
                    isMulti={true}
                    value={renderMethodsOptions?.filter((item) =>
                      value?.includes(item?.value)
                    )}
                    onChange={(selected) => {
                      onChange(selected.map((selected) => selected?.value));
                    }}
                  />
                )}
              />
              {errors?.paymentMethods?.type === "required" && (
                <span className="error-hint">
                  <FormattedMessage id="required" />
                </span>
              )}
            </Form.Group>
          </Row>
          <h5 className="fw-bold form-label">
            2- <FormattedMessage id="addPaymentRestriction" />
          </h5>
          <div
            className={`${watch("paymentMethods")?.length > 0 ? "" : "d-none"}`}
          >
            {(fields?.length > 0 ? fields : [{}])?.map((item, index) => (
              <Row key={index}>
                <Form.Group className="mb-3 col-12">
                  <Form.Label>
                    <FormattedMessage id="ruleType" />
                  </Form.Label>
                  <Controller
                    control={control}
                    name={`rules.${index}.type`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, selected } }) => (
                      <Select
                        isRtl={true}
                        // menuIsOpen
                        isSearchable={false}
                        className="basic-single"
                        classNamePrefix="select"
                        options={rulesGenres}
                        placeholder={formatMessage({ id: "ruleType" })}
                        isMulti={false}
                        value={rulesGenres?.find((o) => o?.value === value)}
                        onChange={({ value }) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                </Form.Group>
                <RuleTypeInputs
                  control={control}
                  index={index}
                  watch={watch}
                  register={register}
                />
              </Row>
            ))}
          </div>
          {/* {fields?.length !== 1 && (
            <div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => remove(index)}
                aria-label="delete"
                title="delete"
              >
                <DeleteIcon width={20} height="auto" color="#fff" />
              </button>
            </div>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            الغاء
          </button>
          <button className="btn btn-blue">تم</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RestrictionModal;
