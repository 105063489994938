import server from "./server";

export const getThemeTemplatesApi = async (deviceType) => {
  const response = await server().get("/themeTemplates", {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};
