import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { addEmptyBrand, getBrands } from "store/actions";

import BreadCrumb from "components/shared/BreadCrumb";
import StickyLoader from "components/shared/StickyLoader";
// import settings from "assets/svgs/Setting.svg";

import { ReactComponent as GridIcon } from "assets/svgs/gridLayout.svg";
import { ReactComponent as ListIcon } from "assets/svgs/listLayout.svg";
import DndBrandsList from "components/brandsDnd/BrandsList";
import useSearchFormParam from "components/shared/SearchHook";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { useIntl } from "react-intl";

const Index = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [registerSearch, handleSubmitSearch, params, EmptyButton] =
    useSearchFormParam();

  const { loading } = useSelector((state) => state.brands);

  const [listView, setListView] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    const paginationQuery = `?page=${params.get("page") ?? "1"}&limit=${
      params.get("limit") ?? "20"
    }`;
    dispatch(getBrands(paginationQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const createEmptyBrand = () => {
    // setLoading(true);
    return {
      id: shortid.generate(),
      name: {
        ar: "",
      },
      // image: `${placeImg}`,
      products: [],
      categories: [],
      isNew: true,
    };
  };
  return (
    <>
      <div className="stores-wrap brands-wrap">
        {loading && <StickyLoader fill="#FC6B14" />}
        <BreadCrumb pageName="العلامات التجارية" />
        <div className="filter-toolbar">
          <div className="d-flex gap-2 align-items-center">
            <button
              className="btn btn-blue"
              onClick={() => {
                dispatch(addEmptyBrand(createEmptyBrand()));
                // setLoading(false);
              }}
            >
              إضافة علامة تجارية +
            </button>

            <div className="ordersServices">
              <div className="orderLayout">
                <button
                  className={listView ? "gridLayout" : "gridLayout active"}
                  onClick={() => setListView(false)}
                >
                  <GridIcon fill="#F2F2F2" stroke="#292D32" />
                </button>
                <button
                  className={listView ? "listLayout active" : "listLayout"}
                  onClick={() => setListView(true)}
                >
                  <ListIcon fill="#292D32" stroke="#292D32" />
                </button>
              </div>
              {/* <button
              type="button"
              className="btn"
              onClick={() => {
                setShowSettingModal(true);
              }}
            >
              <img src={settings} alt="settings" />
              اعدادات
            </button> */}
            </div>
          </div>
          <form
            className="filter-toolbar__search"
            onSubmit={handleSubmitSearch}
          >
            <input
              type="search"
              placeholder={formatMessage({ id: "searchByName" })}
              className="form-control"
              {...registerSearch("txt")}
            />
            <EmptyButton />
            <button>
              <SearchIcon />
            </button>
          </form>
        </div>
        <DndBrandsList listView={listView} />
        {/* <BrandsList listView={listView} /> */}
        {/* <OrdersFilterSidebar /> */}
        <div
          className={showFilter ? "overlay-g active" : "overlay-g"}
          onClick={() => {
            setShowFilter(false);
          }}
        ></div>
      </div>
    </>
  );
};

export default Index;
