import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import StickyLoader from "components/shared/StickyLoader";
import CheckCard from "./CheckCard";

const BankAccountsCards = ({
  setSelectedBank,
  editDeleteFlag = true,
  titleShow = true,
}) => {
  const { loading, settings, dataSaveLoading, bankAccounts } =
    useSelector((state) => state?.settings) || {};

  const renderCurrenciesCheckBoxes = useCallback(
    () =>
      bankAccounts?.map((account, index) => (
        <CheckCard
          key={index}
          editDeleteFlag={editDeleteFlag}
          data={account}
          selectBank={(data) => setSelectedBank(data)}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, bankAccounts]
  );

  return (
    <div className="acc-form bg-transparent w-100">
      {(dataSaveLoading || loading) && <StickyLoader fill="#FC6B14" />}

      <div className={`card-head ${titleShow ? "" : "d-none"}`}>
        <h4>
          <FormattedMessage id="bankAccounts" />
        </h4>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Col xs={12}>
        <Row className="row-gap-2">{renderCurrenciesCheckBoxes()}</Row>
      </Col>
      {/* </form> */}
    </div>
  );
};

export default BankAccountsCards;
