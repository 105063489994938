import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ReactComponent as RoutingIcon } from "assets/svgs/settings/routing-2.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { editStoreSlugSetting } from "store/actions";

const CompanyTitleModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [params] = useSearchParams();

  const { settings, loading } = useSelector((state) => state?.settings) || {};

  useEffect(() => {
    reset(settings || {});
  }, [reset, settings]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {
    // e.preventDefault();

    dispatch(
      editStoreSlugSetting({
        data,
        slug: "walletSettings",
        navigate: handleClose,
      })
    );
  };

  return (
    <Modal
      dialogClassName="productDataModal cash-on-deliver"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <RoutingIcon fill="#FC6B14" />
            <FormattedMessage id="companyTitle" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            <FormattedMessage id="officialCompanyTitle" />
          </h5>
          <p className="mb-3 small text-muted">
            <FormattedMessage id="taxReceiptAddressInfo" />
          </p>
          <Row>
            <Col xs={12}>
              <div className="form-group required">
                <h5>
                  <FormattedMessage id="companyName" />
                </h5>
                <div className="selectHolder ">
                  <input
                    className="form-control form-outline"
                    {...register("title.name", { required: true })}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-group required">
                <h5>
                  <FormattedMessage id="address" />
                </h5>
                <div className="selectHolder ">
                  <input
                    className="form-control form-outline"
                    {...register("title.address", { required: true })}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-group required">
                <h5>
                  <FormattedMessage id="taxId" />
                </h5>
                <div className="selectHolder ">
                  <input
                    className="form-control form-outline"
                    {...register("title.taxId", { required: true })}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {loading && <StickyLoader fill="#FC6B14" />}
    </Modal>
  );
};

export default CompanyTitleModal;
