import server from "./server";

// export const getProductsApi = async (payload) => {
//   const response = payload?.searchParams
//     ? await server().get(
//       `/products?page=${payload?.searchParams?.page || 1}&limit=${payload?.searchParams?.limit || 10
//       }`
//     )
//     : await server().get(`/products?limit=10`);
//   return response.data;
// };

export const getProductsApi = async (payload) => {
  const response = await server().get(`/stores/products${payload || ""}`);
  return response.data;
};

export const getProductsInventoryApi = async (payload) => {
  const response = await server().get(
    `/stores/products/inventory${payload || ""}`
  );
  return response.data;
};

export const getProductsVariationsApi = async () => {
  const response = await server().get("/stores/products/variations");
  return response.data;
};

export const getProductsVariationsStylesApi = async () => {
  const response = await server().get("/stores/products/variationStyles");
  return response.data;
};

export const getSingleProductApi = async (id) => {
  const response = await server().get(`/stores/products/${id}`);
  return response.data;
};

export const addProductApi = async (payload) => {
  const response = await server().post("/stores/products", payload);
  return response.data;
};

export const editProductApi = async ({ id, data }) => {
  const response = await server().put(`/stores/products/${id}`, data);
  return response.data;
};

export const updateProductMainDataApi = async ({ id, data }) => {
  const response = await server().put(`/stores/products/updateMainData/${id}`, {
    ...data,
  });
  return response.data;
};

export const hideProductApi = async (id) => {
  const response = await server().get(`/stores/products/hiddenProducts/${id}`);
  return response.data;
};

export const deleteProductApi = async (id) => {
  const response = await server().delete(`/stores/products/${id}`);
  return response.data;
};

export const deleteMultipleProductsApi = async (data) => {
  const response = await server().delete(
    `/stores/products/deleteMultiProducts/`,
    {
      data,
    }
  );
  return response.data;
};

export const deleteAllProductsInStore = async () => {
  const response = await server().delete(
    `/stores/products/deleteAllProductsInStore`
  );
  return response.data;
};

export const orderProductsApi = async (payload) => {
  const response = await server().put(
    `/stores/products/reorderProducts/${payload?.category}`,
    { products: payload?.products }
  );
  return response.data;
};

export const getProductsTagsApi = async () => {
  const response = await server().get("/stores/products/getAllTags");
  return response.data;
};
