const { default: server } = require("./server");

export const getSettingsApi = async (slug) => {
  const response = await server().get(`/stores/settings/${slug}`);
  return response.data;
};

export const editSettingsApi = async (data) => {
  const response = await server().put(`/stores/settings/siteInfo`, data);

  return response.data;
};

export const editStoreBasicSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/stores/settings/basic/${slug}`, data);

  return response.data;
};

export const editStoreSlugSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/stores/settings/${slug}`, data);
  return response.data;
};

export const editStoreLangsApi = async ({ data }) => {
  const response = await server().put(`/stores/settings/language`, data);

  return response.data;
};
export const editStoreDefaultLanguageApi = async ({ languageId }) => {
  const response = await server().put(
    `/stores/settings/language/isDefault/${languageId}`
  );

  return response.data;
};
export const editStoreDefaultCurrencyApi = async ({ currencyId }) => {
  const response = await server().put(
    `/stores/settings/currency/isDefault/${currencyId}`
  );

  return response.data;
};

export const editStoreCurrenciesApi = async ({ data }) => {
  const response = await server().put(`/stores/settings/currency`, data);

  return response.data;
};

export const getBankAccountApi = async () => {
  const response = await server().get(`/stores/bankAccounts`);

  return response.data;
};

export const addBankAccountApi = async ({ data }) => {
  const response = await server().post(`/stores/bankAccounts`, data);

  return response.data;
};

export const editBankAccountApi = async ({ data, id }) => {
  const response = await server().put(`/stores/bankAccounts/${id}`, data);

  return response.data;
};

export const setDefaultBankAccountApi = async (id) => {
  const response = await server().post(`/stores/bankAccounts/setDefault/${id}`);

  return response.data;
};

export const deleteBankAccountApi = async (id) => {
  const response = await server().delete(`/stores/bankAccounts/${id}`);

  return response.data;
};
