import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
import Swal from "sweetalert2";
import { useIntl, FormattedMessage } from "react-intl";
import { deleteProductsReview, getProductsReviews } from "store/actions";
import useSearchFormParam from "components/shared/SearchHook";
import CustomersFilterSidebar from "components/customers/CustomersFilterSidebar";
import CardsList from "components/productsReviews/CardsList";
import BreadCrumb from "components/shared/BreadCrumb";
import BootyPagination from "components/shared/Pagination";

const Index = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [registerSearch, handleSubmitSearch, searchParams, EmptyButton] =
    useSearchFormParam();

  const { productsReviews, metadata } = useSelector(
    (state) => state.productsReviews
  );
  // const { componentsTypes } = useSelector((state) => state.componentsTypes);
  const deviceType = searchParams.get("deviceType");

  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    searchParams.delete("deviceType");
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    searchParams.set("deviceType", deviceType);
    dispatch(getProductsReviews(paramsStr));
  }, [deviceType, dispatch, searchParams]);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((product) => product !== id));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: `هل تريد حذف (${isCheck?.length}) عنصر!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        isCheck?.map((id) => dispatch(deleteProductsReview(id)));
        setIsCheck([]);
      }
    });
  };

  const CheckedOptions = () =>
    isCheck?.length > 0 && (
      <div className="checked-options">
        <button type="button" className="btn btn-danger" onClick={handleDelete}>
          <DeleteIcon fill="#fff" />
        </button>
      </div>
    );

  const filterToggle = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const filterLength = [...searchParams.entries()]
    ?.map((e) => e[0])
    ?.filter(
      (e) =>
        e !== "page" && e !== "limit" && e !== "nameText" && e !== "deviceType"
    )?.length;

  return (
    <>
      <div className="stores-wrap productsReviews">
        <BreadCrumb pageName={formatMessage({ id: "productsReviews" })} />

        {/* <ControlArea
          btnTxt="اضافة عنصر"
          cardTxt="العناصر"
          icon={themesIcon}
          url={`/themes/components/add?deviceType=${deviceType}`}
        /> */}
        <div className="filter-toolbar">
          <div className="filter-toolbar__right">
            <div className="filter-toolbar__selection">
              {/* <label htmlFor="select-all">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheck?.length}
                />
                <h5>
                  <FormattedMessage id="productsReviews" />{" "}
                  {` (${productsReviews?.length})`}
                </h5>
              </label> */}
              {/* </div>
            <div className="filter-toolbar__layout"> */}
              {/* <button
                className={listView ? "gridLayout" : "gridLayout active"}
                onClick={() => setListView(false)}
              >
                <GridIcon fill="#F2F2F2" stroke="#292D32" />
              </button>
              <button
                className={listView ? "listLayout active" : "listLayout"}
                onClick={() => setListView(true)}
              >
                <ListIcon fill="#292D32" stroke="#292D32" />
              </button> */}
            </div>
          </div>
          <div className="filter-toolbar__left">
            <div className="filter-toolbar__action">
              <button
                className="btn btn-black"
                onClick={filterToggle}
                type="button"
              >
                <FilterIcon />
                <FormattedMessage id="filter" />
                {!!filterLength && (
                  <span className="filterLength">{filterLength}</span>
                )}
              </button>
            </div>
            {/* <div className="filter-toolbar__settings">
                            <SettingsDropdown />
                        </div> */}
            <CheckedOptions />
            <form
              className="filter-toolbar__search"
              onSubmit={handleSubmitSearch}
            >
              <input
                type="search"
                placeholder={formatMessage({
                  id: "nameSearch",
                })}
                className="form-control"
                {...registerSearch("txt")}
              />
              <EmptyButton />

              <button>
                <SearchIcon />
              </button>
            </form>
          </div>
          {/* <ParamsFilters types={componentsTypes} href="/themes/components" /> */}
        </div>
        <CardsList
          // listView={listView}
          // metadata={metadata}
          filteredItems={productsReviews}
          isCheck={isCheck}
          handleClick={handleClick}
        />
        <CustomersFilterSidebar
          searchParams={searchParams}
          href={"/productsReviews"}
          filtersObj={{
            type: {
              type: "radio",
              data: ["question", "storeRated", "productRated"],
            },
            // types: {
            //   type: "checkbox",
            //   data: componentsTypes,
            // },
          }}
        />
        <div className="overlay-g" onClick={filterToggle}></div>
        <BootyPagination metaData={metadata} />

        {/* <Table data={components} /> */}
      </div>
    </>
  );
};

export default Index;
