import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  editStoreSlugSetting,
  getBankAccounts,
  getBanks,
  getSettings,
} from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as EditCardIcon } from "assets/svgs/settings/card-edit.svg";
import FreeShippingModal from "components/settings/storeShipping/FreeShippingConditions";
import CashOnDeliveryModal from "components/settings/storeShipping/CashOnDeliveryModal";
import DeliveryCompaniesOptions from "components/settings/storeShipping/DeliveryCompaniesOptions";
import CalculateShipingModal from "components/settings/storeShipping/CalculateShipingModal";
import BankModal from "components/settings/storeWallet/BankModal";
import BreadCrumb from "components/shared/BreadCrumb";
import { useForm } from "react-hook-form";

let triggerFormTimeout = null;

const StoreOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { formatMessage } = useIntl();

  const { register, handleSubmit, reset } = useForm();
  const {
    register: registerProducts,
    handleSubmit: handleSubmitProducts,
    reset: resetProducts,
  } = useForm();
  const {
    register: registerOrders,
    handleSubmit: handleSubmitOrders,
    reset: resetOrders,
  } = useForm();

  const {
    register: registerCustomers,
    handleSubmit: handleSubmitCustomers,
    reset: resetCustomers,
  } = useForm();

  const {
    register: registerQE,
    handleSubmit: handleSubmitQE,
    reset: resetQE,
  } = useForm();

  const { settings, dataSaveLoading, loading } =
    useSelector((state) => state?.settings) || {};

  const [selectedBank, setSelectedBank] = useState();
  const [bankAccountModal, setBankAccountModal] = useState(false);

  useEffect(() => {
    dispatch(getSettings("optionSettings"));
    dispatch(getBanks());
    dispatch(getBankAccounts());
  }, [dispatch]);
  useEffect(() => {
    reset(settings?.generalOptions);

    resetProducts(settings?.products);
    resetOrders(settings?.orders);
    resetCustomers(settings?.customers);
    resetQE(settings?.questionsAndEvaluations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reset,
    resetCustomers,
    resetOrders,
    resetProducts,
    resetQE,
    JSON.stringify(settings),
  ]);

  const triggerFormSubmit = (e) => {
    const form = e.target.closest("form");
    // if (form) form?.requestSubmit();

    if (triggerFormTimeout !== null) clearTimeout(triggerFormTimeout);

    triggerFormTimeout = setTimeout(() => {
      if (form) form?.children?.[0]?.click();
    }, 1500);
  };

  const CheckLabel = ({ name, label = name, register }) => (
    <div className="card shipping-card">
      <div className="info">
        <p>
          <FormattedMessage id={label} />
        </p>
      </div>
      <div className="switcher-wrapper">
        <label className="switcher">
          <input
            type="checkbox"
            className="switcher-input"
            {...register(name, { onChange: triggerFormSubmit })}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </div>
    </div>
  );

  const onSubmit = (data) => {
    dispatch(
      editStoreSlugSetting({
        data,
        slug: `optionSettings/${data.slug}`,
        navigate,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page store-paymentmethods">
      <BreadCrumb
        pageName={formatMessage({
          id: "storeOptions",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <Accordion defaultActiveKey="1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button className="d-none"></button>
          <Accordion.Item eventKey="1" className="delivery-companies">
            <div className="acc-form card shipping-methods">
              {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
              <Accordion.Header>
                <div className="card-head mb-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      <i>
                        <EditCardIcon />
                      </i>
                      <div>
                        <h4>
                          <FormattedMessage id="generalOptions" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="items">
                  <CheckLabel name="arabicNumbers" register={register} />
                  <CheckLabel name="madeByTjaara" register={register} />
                  <CheckLabel
                    name="allocateCasesAccordingToReports"
                    register={register}
                  />
                  <CheckLabel name="maintenanceMode" register={register} />

                  {/* {(shipping?.methods || [1, 2, 3])?.map((method, index) => (
                    <div className="card shipping-card" key={index}>
                      <div className="info">
                        <p>{method?.title || "شركة الشحن السريعة"}</p>
                      </div>
                      <Link to={`/settings/store-shipping/${method?.id}`}>
                        <FormattedMessage id="moreSettings" />
                      </Link>
                      <div className="switcher-wrapper">
                        <label className="switcher">
                          <input type="checkbox" className="switcher-input" />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </label>
                      </div>
                    </div>
                  ))} */}
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <input type="hidden" {...register("slug")} value="generalOptions" />
        </form>

        <form onSubmit={handleSubmitProducts(onSubmit)}>
          <button className="d-none"></button>
          <Accordion.Item eventKey="2" className="delivery-companies">
            <div className="acc-form card shipping-methods">
              {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
              <Accordion.Header>
                <div className="card-head mb-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      <i>
                        <EditCardIcon />
                      </i>
                      <div>
                        <h4>
                          <FormattedMessage id="products" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="items">
                  <CheckLabel
                    name="repeatingInCart"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showPurchaseTimes"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showOutOfStockAtBottom"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showMoreDetails"
                    register={registerProducts}
                  />
                  <CheckLabel name="displayMark" register={registerProducts} />
                  <CheckLabel
                    name="showProductsMayLiked"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showSpecialOffers"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="addFreeOfferToCart"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showProductPriceRange"
                    register={registerProducts}
                  />
                  <CheckLabel
                    name="showWeightInBilling"
                    register={registerProducts}
                  />
                  <CheckLabel name="showSKU" register={registerProducts} />
                  <CheckLabel name="showHSCode" register={registerProducts} />
                  <CheckLabel
                    name="showWeightForShippingCompany"
                    register={registerProducts}
                  />
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <input type="hidden" {...registerProducts("slug")} value="products" />
        </form>

        <form onSubmit={handleSubmitOrders(onSubmit)}>
          <button className="d-none"></button>
          <Accordion.Item eventKey="3" className="delivery-companies">
            <div className="acc-form card shipping-methods">
              {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
              <Accordion.Header>
                <div className="card-head mb-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      <i>
                        <EditCardIcon />
                      </i>
                      <div>
                        <h4>
                          <FormattedMessage id="orders" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="items">
                  <CheckLabel
                    name="receivingOrders"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="orderReceptionTimes"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="addNotesPermitting"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="cancelOrdersPermitting"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="setStatusExecutedUpon"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="disablePaymentDeadlineBankTransfer"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="displayDeliveryIndicator"
                    register={registerOrders}
                  />
                  <CheckLabel name="activateAgain" register={registerOrders} />
                  <CheckLabel
                    name="acknowledgmentBeforeSending"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="customizeCompletionPage"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="customPrepareOrderMenu"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="substractDeliveryFee"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="discountBillsLading"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="allowAddingAttachmentsToMessage"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="statusNotifications"
                    register={registerOrders}
                  />
                  <CheckLabel
                    name="sendDigitalProducts"
                    register={registerOrders}
                  />
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <input type="hidden" {...registerOrders("slug")} value="orders" />
        </form>

        <form onSubmit={handleSubmitCustomers(onSubmit)}>
          <button className="d-none"></button>
          <Accordion.Item eventKey="4" className="delivery-companies">
            <div className="acc-form card shipping-methods">
              {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
              <Accordion.Header>
                <div className="card-head mb-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      <i>
                        <EditCardIcon />
                      </i>
                      <div>
                        <h4>
                          <FormattedMessage id="customers" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="items">
                  <CheckLabel
                    name="optionalCustomerEmail"
                    register={registerCustomers}
                  />
                  <CheckLabel
                    name="loginWithEmail"
                    register={registerCustomers}
                  />
                  <CheckLabel
                    name="mergeCustomerCartAfterLogin"
                    register={registerCustomers}
                  />
                  <CheckLabel
                    name="decideCustomerAddressMethod"
                    register={registerCustomers}
                  />
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <input
            type="hidden"
            {...registerCustomers("slug")}
            value="customers"
          />
        </form>
        <form onSubmit={handleSubmitQE(onSubmit)}>
          <button className="d-none"></button>
          <Accordion.Item eventKey="5" className="delivery-companies">
            <div className="acc-form card shipping-methods">
              {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
              <Accordion.Header>
                <div className="card-head mb-0 productDataModal">
                  <div className="form-group mb-0">
                    <div className="form-head mb-0">
                      <i>
                        <EditCardIcon />
                      </i>
                      <div>
                        <h4>
                          <FormattedMessage id="questionsRatings" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <div className="items">
                  <CheckLabel
                    name="postingQuestionsWithoutReview"
                    register={registerQE}
                  />
                  <CheckLabel
                    name="activateQuestionsonIntroductoryPages"
                    register={registerQE}
                  />
                  <CheckLabel
                    name="canAddQuestionsInProductPage"
                    register={registerQE}
                  />
                  <CheckLabel
                    name="preventCustomersFromAddingQuestions"
                    register={registerQE}
                  />
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <input
            type="hidden"
            {...registerQE("slug")}
            value="questionsAndEvaluations"
          />
        </form>
      </Accordion>
      <BankModal
        selectedBank={selectedBank}
        modalShow={bankAccountModal}
        setModalShow={setBankAccountModal}
        settings={settings}
      />

      <FreeShippingModal show={params?.get("modal") === "free-shipping"} />
      <CashOnDeliveryModal show={params?.get("modal") === "cash-delivery"} />
      <DeliveryCompaniesOptions
        show={params?.get("modal") === "delivery-settings"}
      />
      {/* <DeliveryCompaniesRules
          show={params?.get("modal") === "delivery-rules"}
        /> */}
      <CalculateShipingModal show={params?.get("modal") === "delivery-price"} />
    </div>
  );
};

export default StoreOptions;
