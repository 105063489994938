import React, { useEffect } from "react";
import { Col, Form, Modal, Nav, Tab } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategories, getProducts } from "store/actions";
import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { priceInputValidations } from "helpers/configs";
import Select from "react-select";

const CashOnDeliveryModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, control, watch } = useForm();
  const [params] = useSearchParams();

  const { products, loading: loadingProducts } = useSelector(
    (state) => state.products
  );
  const { flatCategories, loading: loadingCategories } = useSelector(
    (state) => state.productCategories
  );
  const { loading: loadingGroups } = useSelector((state) => state.groups);

  const selectedItemsType = watch("selectedItemsType");

  useEffect(() => {
    let searchParams1 = new URLSearchParams(params);
    searchParams1.delete("modal");
    const paramsStr =
      searchParams1?.size > 0 ? `?${searchParams1.toString()}` : "";
    selectedItemsType === "categories"
      ? dispatch(getProductCategories(paramsStr))
      : dispatch(getProducts(paramsStr));
  }, [dispatch, params, reset, selectedItemsType, type]);

  useEffect(() => {
    if (type === "add") reset();
  }, [reset, type]);

  useEffect(() => {
    reset({});
  }, [coupon, reset]);

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    if (searchTimeout !== null) clearInterval(searchTimeout);
    searchTimeout = setTimeout(() => {
      navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
    }, 400);
  };

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const getOptionsByName = (arr) =>
    //add default option
    [
      // {
      //   label: "اختر او ابحث",
      //   value: "",
      // },
    ].concat(
      arr?.map((item) => ({
        label: item.name?.[locale],
        value: getId(item),
      }))
    );
  const filteredSelectedItems = (txt) =>
    products?.filter(
      (item) =>
        JSON.stringify({ id: getId(item), name: item?.name })
          .toLowerCase()
          .indexOf(txt.toLowerCase()) !== -1
    );

  const handleClose = () => {
    removeParams("modal");
  };

  const onSubmit = (data) => {};

  return (
    <Modal
      dialogClassName="productDataModal cash-on-deliver"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalHeaderIcon fill="#FC6B14" />
            <FormattedMessage id="cashOnDeliverySettings" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Tab.Container
              defaultActiveKey={0}
              transition={true}
              timeout={1000}
            >
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey={0}>
                    <FormattedMessage id="cashOnDeliveryConditions" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={1}>
                    <FormattedMessage id="cashOnDeliveryExceptions" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={0}>
                  <Col lg={12} className="form-group d-flex flex-column gap-3">
                    <div className="form-body required">
                      <h5>
                        <FormattedMessage id="maxAmount" />
                      </h5>
                      <input
                        {...priceInputValidations}
                        className="form-control"
                        {...register("maxAmount", {
                          required: true,
                        })}
                      />
                    </div>

                    <div className="form-body required">
                      <h5>
                        <FormattedMessage id="maxWeight" />
                      </h5>
                      <input
                        {...priceInputValidations}
                        className="form-control"
                        {...register("maxWeight", {
                          required: true,
                        })}
                      />
                    </div>

                    <div className="form-body">
                      <label className="w-100 cursor-pointer">
                        <FormattedMessage id="stopCashOnDeliveryUncommitted" />

                        <div className="switcher-wrapper">
                          <label className="switcher mb-0">
                            <input
                              type="checkbox"
                              className="switcher-input"
                              {...register("stopCashOnDeliveryUncommitted")}
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </label>
                        </div>
                      </label>
                    </div>
                  </Col>
                </Tab.Pane>
                <Tab.Pane eventKey={1}>
                  <Col lg={12} className="form-group d-flex flex-column gap-3">
                    <div className="form-body required">
                      <h5>
                        <FormattedMessage id="exceptionProducts" />
                      </h5>
                      <div className="selectHolder">
                        <Controller
                          control={control}
                          name={`exceptionProducts`}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value: value1, selected },
                          }) => (
                            <Select
                              isMulti={true}
                              isRtl={true}
                              isSearchable={true}
                              className="basic-single w-100"
                              classNamePrefix="select"
                              options={getOptionsByName(products)}
                              placeholder={formatMessage({
                                id: "exceptionProducts",
                              })}
                              onChange={(selected) => {
                                onChange(
                                  selected.map((selected) => selected?.value)
                                );
                              }}
                              value={getOptionsByName(
                                products?.filter((s) =>
                                  value1?.includes(getId(s))
                                )
                              )}
                              onInputChange={(value) => {
                                if (filteredSelectedItems(value)?.length <= 0)
                                  updateParams("nameText", value);
                              }}
                            />
                          )}
                        />
                        {/* <CodeIcon fill="#E4E4E4" /> */}
                      </div>
                    </div>

                    <div className="form-body required">
                      <h5>
                        <FormattedMessage id="exceptionCategories" />
                      </h5>
                      <div className="selectHolder">
                        <Controller
                          control={control}
                          name={`exceptionCategories`}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value: value1, selected },
                          }) => (
                            <Select
                              isMulti={true}
                              isRtl={true}
                              isSearchable={true}
                              className="basic-single w-100"
                              classNamePrefix="select"
                              options={getOptionsByName(flatCategories)}
                              placeholder={formatMessage({
                                id: "exceptionCategories",
                              })}
                              onChange={(selected) => {
                                onChange(
                                  selected.map((selected) => selected?.value)
                                );
                              }}
                              value={getOptionsByName(
                                flatCategories?.filter((s) =>
                                  value1?.includes(getId(s))
                                )
                              )}
                              onInputChange={(value) => {
                                if (filteredSelectedItems(value)?.length <= 0)
                                  updateParams("nameText", value);
                              }}
                            />
                          )}
                        />
                        {/* <CodeIcon fill="#E4E4E4" /> */}
                      </div>
                    </div>
                  </Col>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
      {(loadingGroups || loadingCategories || loadingProducts) && (
        <StickyLoader fill="#FC6B14" />
      )}
    </Modal>
  );
};

let searchTimeout = null;

export default CashOnDeliveryModal;
