import server from "./server";

export const getThemePagesApi = async (deviceType) => {
  const response = await server().get("/themePages", {
    headers: {
      deviceType,
    },
  });
  return response.data;
};

export const getThemeComponentsTypeApi = async (deviceType) => {
  const response = await server().get("/themeComponentsTypes", {
    headers: {
      deviceType,
    },
  });
  return response.data;
};

export const getThemeFontsApi = async (deviceType) => {
  const response = await server().get("/fonts", {
    headers: {
      deviceType,
    },
  });
  return response.data;
};

export const getThemeApi = async ({ storeId, themeId }) => {
  const response = await server().get(`/stores/themes/${themeId}`, {
    headers: {
      store: storeId,
    },
  });
  return response.data;
};

export const editThemeComponentApi = async ({
  storeId,
  themeId,
  formSubmitData,
  deviceType,
}) => {
  const response = await server().put(
    `/stores/themes/changeThemeComponents/${themeId}`,
    { components: formSubmitData },
    {
      headers: {
        store: storeId,
        deviceType,
      },
    }
  );
  return response.data;
};

export const editThemeCssApi = async ({
  storeId,
  themeId,
  deviceType,
  formData,
}) => {
  const response = await server().put(
    `/stores/themes/editCssTheme/${themeId}`,
    { css: formData },
    {
      headers: {
        deviceType: deviceType,
      },
    }
  );
  return response.data;
};

export const editThemeSettingsApi = async ({
  storeId,
  themeId,
  deviceType,
  formData,
}) => {
  const response = await server().put(
    `/stores/themes/changeThemeSettings/${themeId}`,
    { themeSettings: formData },
    {
      headers: {
        deviceType: deviceType,
      },
    }
  );
  return response.data;
};

export const editStoreSettingsApi = async ({ formData }) => {
  const response = await server().put(
    `/stores/settings/basic/appSetting`,
    formData
  );
  return response.data;
};
