import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_INVENTORY,
    GET_PRODUCTS_INVENTORY_SUCCESS,
    GET_PRODUCTS_INVENTORY_FAILURE,
    ADD_PRODUCT,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAILURE,
    GET_SINGLE_PRODUCT,
    GET_SINGLE_PRODUCT_SUCCESS,
    GET_SINGLE_PRODUCT_FAILURE,
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCTS,
    DELETE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS_FAILURE,
    ADD_FAKE_PRODUCT,
    ADD_FAKE_PRODUCT_SUCCESS,
    ADD_FAKE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_MAIN_DATA,
    UPDATE_PRODUCT_MAIN_DATA_SUCCESS,
    UPDATE_PRODUCT_MAIN_DATA_FAILURE,
    EDIT_PRODUCT,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILURE,
    DELETE_EMPTY_PRODUCT,
    DELETE_EMPTY_PRODUCT_SUCCESS,
    DELETE_EMPTY_PRODUCT_FAILURE,
    GET_VARIATIONS,
    GET_VARIATIONS_SUCCESS,
    GET_VARIATIONS_FAILURE,
    GET_VARIATIONS_STYLES,
    GET_VARIATIONS_STYLES_SUCCESS,
    GET_VARIATIONS_STYLES_FAILURE,
    LOAD_MORE_PRODUCTS,
    LOAD_MORE_PRODUCTS_SUCCESS,
    LOAD_MORE_PRODUCTS_FAILURE,
    ORDER_PRODUCTS,
    ORDER_PRODUCTS_SUCCESS,
    ORDER_PRODUCTS_FAILURE,
    GET_PRODUCTS_TAGS,
    GET_PRODUCTS_TAGS_SUCCESS,
    GET_PRODUCTS_TAGS_FAILURE,
    HIDE_PRODUCT,
    HIDE_PRODUCT_SUCCESS,
    HIDE_PRODUCT_FAILURE,
} from "./actionTypes";
import { getId } from "helpers/functions";

const initialState = {
    products: [],
    metadata: {},
    loading: false,
    error: "",
    singleProduct: {},
    tags: [],
};

const stores = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_PRODUCTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: action.payload.products,
                metadata: {
                    ...(action.payload.metadata || {}),
                    results: action.payload?.products?.length,
                },
            };
            break;
        case GET_PRODUCTS_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================
        case GET_PRODUCTS_TAGS:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_PRODUCTS_TAGS_SUCCESS:
            state = {
                ...state,
                loading: false,
                tags: action.payload.tags,
            };
            break;
        case GET_PRODUCTS_TAGS_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================
        case GET_PRODUCTS_INVENTORY:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_PRODUCTS_INVENTORY_SUCCESS:
            state = {
                ...state,
                loading: false,
                // products: action.payload.products.reverse(),
                products: action.payload.products,
                metadata: {
                    ...(action.payload.metadata || {}),
                    results: action.payload?.products?.length,
                },
            };
            break;
        case GET_PRODUCTS_INVENTORY_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case LOAD_MORE_PRODUCTS:
            state = {
                ...state,
                loading: true,
            };
            break;
        case LOAD_MORE_PRODUCTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: action.payload.products,
                metadata: action.payload.metadata,
            };
            break;
        case LOAD_MORE_PRODUCTS_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;
        // ===============================================================

        case GET_SINGLE_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case GET_SINGLE_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                singleProduct: action.payload.product,
            };
            break;
        case GET_SINGLE_PRODUCT_FAILURE:
            state = {
                ...state,
                error: action.payload,
                loading: false,
                singleProduct: {},
            };
            break;

        // ===============================================================

        case ADD_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case ADD_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: action?.payload?.loading || false,
                products: [action.payload.product, ...state.products],

                error: "",
            };
            break;

        case ADD_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload });

            // toast.error("فشلت عملية اضافة المنتج");

            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case UPDATE_PRODUCT_MAIN_DATA:
            state = {
                ...state,
                loading: true,
            };
            break;

        case UPDATE_PRODUCT_MAIN_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: [
                    ...state.products.map((product) => {
                        if (getId(product) !== getId(action.payload))
                            return product;
                        return action.payload;
                    }),
                ],
            };
            break;

        case UPDATE_PRODUCT_MAIN_DATA_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;
        // ===============================================================

        case HIDE_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case HIDE_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: [
                    ...state.products.map((product) => {
                        if (getId(product) !== getId(action.payload))
                            return product;
                        return action.payload;
                    }),
                ],
            };
            break;

        case HIDE_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case DELETE_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case DELETE_PRODUCT_SUCCESS:
            const productsAfterDeleteing = [
                ...state?.products?.filter(
                    (product) => getId(product) !== action.payload
                ),
            ];
            state = {
                ...state,
                loading: false,
                products: productsAfterDeleteing,
            };
            break;

        case DELETE_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        // ===============================================================

        case DELETE_PRODUCTS:
            state = {
                ...state,
                loading: true,
            };
            break;

        case DELETE_PRODUCTS_SUCCESS:
            const productsAfterDeleteings = [
                ...state?.products?.filter(
                    (product) => !action.payload?.includes(getId(product))
                ),
            ];
            state = {
                ...state,
                loading: false,
                products: productsAfterDeleteings,
            };
            break;

        case DELETE_PRODUCTS_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        // ===============================================================

        case ADD_FAKE_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case ADD_FAKE_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: [action.payload, ...state.products],
            };
            break;

        case ADD_FAKE_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case EDIT_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;
        case EDIT_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: state.products.map((product) => {
                    if (getId(product) !== getId(action.payload.product))
                        return product;
                    return action.payload.product;
                }),
            };
            break;
        case EDIT_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload, autoClose: 6000 });
            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case DELETE_EMPTY_PRODUCT:
            state = {
                ...state,
                loading: true,
            };
            break;

        case DELETE_EMPTY_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: state.products.filter(
                    (product) => product.id !== action.payload.id
                ),
            };
            break;

        case DELETE_EMPTY_PRODUCT_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        // ===============================================================

        case GET_VARIATIONS:
            state = {
                ...state,
                loading: true,
            };
            break;

        case GET_VARIATIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                variations: action.payload.variations,
            };
            break;

        case GET_VARIATIONS_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;

        // ===============================================================

        case GET_VARIATIONS_STYLES:
            state = {
                ...state,
                loading: true,
            };
            break;

        case GET_VARIATIONS_STYLES_SUCCESS:
            state = {
                ...state,
                loading: false,
                variationsStyles: action.payload.variationStyles,
            };
            break;

        case GET_VARIATIONS_STYLES_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;
        // ===============================================================

        case ORDER_PRODUCTS:
            state = {
                ...state,
                loading: true,
            };
            break;

        case ORDER_PRODUCTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                products: action.payload.orderedProducts,
                // variationsStyles: action.payload.variationTypes,
            };
            break;

        case ORDER_PRODUCTS_FAILURE:
            // toastErrorMessage({ error: action.payload });

            state = { ...state, error: action.payload, loading: false };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default stores;
