import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/settings/empty-wallet-remove.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getGroups,
  getPaymentMethods,
  getProductCategories,
  getProducts,
} from "store/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import BreadCrumb from "components/shared/BreadCrumb";
import RestrictionModal from "./RestrictionModal";

const StorePaymentRules = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [params] = useSearchParams();
  const { handleSubmit, reset, control, watch } = useForm();

  const { append, update } = useFieldArray({
    control,
    name: "rules",
  });

  const { loading } = useSelector((state) => state.customers);

  const { loading: loadingCategories } = useSelector(
    (state) => state.productCategories
  );
  const { loading: loadingGroups } = useSelector((state) => state.groups);

  const restrictions = [1, 2, 3, 4, 5];

  const selectedItemsType = watch("selectedItemsType");

  const [showModal, setShowModal] = useState(false);
  const [selectedRestriction, setSelectedRestriction] = useState({});

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getGroups());
    dispatch(getCountries());
    dispatch(getProductCategories());
    dispatch(getPaymentMethods());
  }, [dispatch]);

  useEffect(() => {
    let searchParams1 = new URLSearchParams(params);
    searchParams1.delete("modal");
    const paramsStr =
      searchParams1?.size > 0 ? `?${searchParams1.toString()}` : "";
    selectedItemsType === "categories"
      ? dispatch(getProductCategories(paramsStr))
      : dispatch(getProducts(paramsStr));
  }, [dispatch, params, reset, selectedItemsType]);

  useEffect(() => {
    reset({});
  }, [reset]);

  const handleRestrictionModal = (item) => {
    setSelectedRestriction(item);
    setShowModal(true);
  };

  const CheckLabel = ({ item = "", label = item?.name?.[locale] }) => (
    <div className="card shipping-card w-100">
      <div className="info">
        <a href="#a" onClick={() => handleRestrictionModal(item)}>
          <p>
            <FormattedMessage id={label || "Label placeholder"} />
          </p>
        </a>
      </div>
      <div className="switcher-wrapper">
        <label className="switcher">
          <input
            type="checkbox"
            className="switcher-input"
            // {...register(item?.name?.[locale])}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </label>
      </div>
    </div>
  );

  const renderRestrictions = restrictions.map((item, index) => (
    <CheckLabel item={item} key={index} />
  ));

  const onSubmit = (data) => {
    console.log("data", data);
    navigate("/settings/store-shipping");
  };

  return (
    <>
      <BreadCrumb
        pageName={formatMessage({
          id: "paymentsRestrictions",
        })}
        parent={{ text: formatMessage({ id: "settings" }), link: "/settings" }}
      />
      <Card className="store-payment-rules store-settings-page">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Title className="mb-4">
            <h5>
              <ModalHeaderIcon fill="#FC6B14" />
              <FormattedMessage id="paymentsRestrictions" />
            </h5>
            <div>
              <button
                type="button"
                className="btn btn-blue my-2"
                onClick={() => setShowModal(true)}
              >
                <FormattedMessage id="add" />
              </button>
            </div>
          </Card.Title>

          <Card.Body>
            <div className="d-flex flex-column gap-1 w-100 shipping-methods">
              <div defaultActiveKey="0" flush className="items mb-3">
                {renderRestrictions}
              </div>
            </div>
            <button type="submit" className="btn btn-blue">
              حفظ
            </button>
          </Card.Body>
        </Form>
        {(loading || loadingGroups || loadingCategories) && (
          <StickyLoader fill="#FC6B14" />
        )}
      </Card>

      <RestrictionModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        append={append}
        update={update}
        selectedItem={selectedRestriction}
      />
    </>
  );
};

export default StorePaymentRules;
