import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { addCoupon, editCoupon } from "store/actions";
import { getId } from "helpers/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import ConditionsField from "./ConditionsFields";

let searchTimeout = null;

const FreeShippingModal = ({ show, setShow, coupon, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, control, watch, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "conditions",
  });
  const [params] = useSearchParams();

  const { products, loading: loadingProducts } = useSelector(
    (state) => state.products
  );
  const { flatCategories, loading: loadingCategories } = useSelector(
    (state) => state.productCategories
  );

  const { loading: loadingCustomers } = useSelector((state) => state.customers);

  const { loading: loadingGroups } = useSelector((state) => state.groups);

  const selectedItemsType = watch("selectedItemsType");

  useEffect(() => {
    if (type === "add") reset();
  }, [reset, type]);

  useEffect(() => {
    reset({
      code: coupon?.code,
      type: coupon?.type,
      amount: coupon?.amount,
      startDate: coupon?.startDate,
      endDate: coupon?.endDate,
      maxAllUsageCount: coupon?.maxAllUsageCount,
      maxUserUsageCount: coupon?.maxUserUsageCount,
      isFreeShipping: coupon?.isFreeShipping,
      minimumPurchases: coupon?.minimumPurchases,
      isMarketingCoupon: coupon?.isMarketingCoupon,
      clientEmailDomain: coupon?.clientEmailDomain,
      maximumDiscountAmount: coupon?.maximumDiscountAmount,
      includeCategories: coupon?.includeCategories?.map((e) => getId(e)),
      includeProducts: coupon?.includeProducts?.map((e) => getId(e)),
      includeUsers: coupon?.includeUsers?.map((e) => getId(e)),
    });
  }, [coupon, reset]);

  const removeParams = (name) => {
    params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
  };

  const handleClose = () => {
    removeParams("modal");
  };

  const updateParams = (name, value) => {
    params.set(name, value);
    if (!value) params.delete(name);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    if (searchTimeout !== null) clearInterval(searchTimeout);
    searchTimeout = setTimeout(() => {
      navigate(`${window.location.pathname}${paramsStr}`, { replace: true });
    }, 400);
  };

  const selectedItemsTypeData =
    selectedItemsType?.length > 0
      ? selectedItemsType === "categories"
        ? flatCategories
        : products
      : [];

  const onSubmit = (data) => {
    if (type === "add") {
      dispatch(
        addCoupon({
          data,
          callBack: () => setShow(false),
        })
      );
      // setShow(false);
    } else {
      dispatch(
        editCoupon({
          data,
          id: coupon?.id,
          callBack: () => setShow(false),
        })
      );
      // setShow(false);
    }
  };

  return (
    <>
      <Modal
        dialogClassName="productDataModal free-shipping"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <ModalHeaderIcon fill="#FC6B14" />
              <FormattedMessage id="storeShippingMethodsSettings" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="form-body">
                {fields?.map((field, index) => (
                  <>
                    <ConditionsField
                      key={index}
                      {...{
                        index,
                        control,
                        register,
                        setValue,
                        updateParams,
                        selectedItemsType,
                        selectedItemsTypeData,
                        watch,
                        remove,
                      }}
                    />
                    <hr />
                  </>
                ))}
              </div>
              <div className="add-condition">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => append({})}
                >
                  <FormattedMessage id="addCondition" /> +
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline"
              onClick={handleClose}
            >
              إلغاء
            </button>
            <button type="submit" className="btn btn-blue">
              حفظ
            </button>
          </Modal.Footer>
        </Form>
        {(loadingCategories ||
          loadingProducts ||
          loadingCustomers ||
          loadingGroups) && <StickyLoader fill="#FC6B14" />}
      </Modal>
    </>
  );
};

export default FreeShippingModal;
