import server from "./server";

export const getProductTypesApi = async () => {
  const response = await server().get("/stores/products/types");
  return response.data;
};

export const addProductTypesApi = async (payload) => {
  const response = await server().post("/stores/products/types", payload);
  return response.data;
};

export const editProductTypesApi = async ({ id, data }) => {
  const response = await server().put(`/stores/products/types/${id}`, data);
  return response.data;
};

export const deleteProductTypesApi = async (id) => {
  const response = await server().delete(`/stores/products/types/${id}`);
  return response.data;
};
