import server from "./server";

export const getProductCategoriesApi = async (payload) => {
  const response = await server().get(
    `/stores/products/categories${payload || ""}`
  );
  return response.data;
};

export const getSingleProductCategoryApi = async (id) => {
  const response = await server().get(`/stores/products/categories/${id}`);
  return response.data;
};

export const addProductCategoriesApi = async (payload) => {
  const response = await server().post("/stores/products/categories", payload);
  return response.data;
};

export const editProductCategoriesApi = async ({ id, data }) => {
  const response = await server().put(
    `/stores/products/categories/${id}`,
    data
  );
  return response.data;
};

export const editProductCategoriesOrderApi = async (data) => {
  const response = await server().put(
    `/stores/products/categories/reorderCategories`,
    data
  );
  return response.data;
};

export const deleteProductCategoriesApi = async (id) => {
  const response = await server().delete(`/stores/products/categories/${id}`);
  return response.data;
};
